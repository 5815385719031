export const Faqs = {
  items:
    [
      {
        "question": "What is WePromoLink?",
        "answer": "WePromoLink is a platform that allows users to create marketing campaigns with images, budgets, descriptions, titles, Earn Per Click (EPC) rates, and destination URL. It also enables users to generate affiliate links for these campaigns and share them on their social networks."
      },
      {
        "question": "How does the Earn Per Click (EPC) system work?",
        "answer": "The EPC is the amount you pay to another user when they generate a click on your campaign's link. Users who create affiliate links and drive clicks to your campaign receive EPC units from your campaign's budget."
      },      
      {
        "question": "How are payments handled on WePromoLink?",
        "answer": "WePromoLink exclusively uses Stripe for payment processing. Users can subscribe to paid plans. Users have the option to request withdrawals."
      },
      {
        "question": "Can I track the performance of my campaigns?",
        "answer": "Yes, all users have access to a dashboard that provides various statistics and analytics to help track the performance of their campaigns, including click data, budget utilization, and more."
      },
      {
        "question": "How do I create an affiliate link for a campaign?",
        "answer": "To create an affiliate link, simply click on 'promote' in the campaign from the platform's feed, and you'll find an option to generate an affiliate link. Share this link on your social networks to start earning EPC units."
      },
      {
        "question": "Is there a minimum budget requirement for campaigns?",
        "answer": "Yes, campaigns must have a minimum budget to ensure that there are EPC units available for users who create affiliate links. The specific minimum budget may vary based on the campaign type and subscription plan."
      },
      {
        "question": "Can I upgrade or downgrade my subscription plan?",
        "answer": "The feature to upgrade or downgrade a subscription plan will be implemented soon."
      },
      {
        "question": "Who owns WePromoLink?",
        "answer": "WePromoLink is owned by B-Tech Innovation Studios LLC, a company based in Delaware, USA. The company's physical address is 651 N Broad St, Suite 201, Middletown, with the zip code 19709."
      },
      {
        "question": "What is the refund policy for subscription plans?",
        "answer": "For subscription plans with monthly payments, WePromoLink offers a 30-day money-back guarantee. However, please note that this guarantee does not apply to deposits, as they are committed to paying for the clicks generated by other users."
      },
      {
        "question": "How do I contact customer support?",
        "answer": "For any questions, issues, or assistance, please contact our customer support team through the 'Contact Us' section on the platform. We're here to help!"
      }
    ]
};